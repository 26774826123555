import styled from "styled-components"
import { device } from "../../utils/breakpoints"

export const Container = styled.div`
  margin: 18px 0;
  padding-right: 20px;

  @media ${device.tablet} {
    margin-top: 0;
    padding-right: 40px;
  }

  @media ${device.large} {
    max-width: 610px;
    padding-right: 0px;
  }
`
export const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  color: ${props => props.theme.colors.black};
  letter-spacing: -0.5px;
  line-height: 40px;
  margin-top: 8px;
  margin-bottom: ${props => (props.article ? null : "20px")};

  @media ${device.large} {
    font-size: 48px;
    color: #19191a;
    letter-spacing: -1px;
    line-height: 48px;
    margin-top: 16px;
    margin-bottom: ${props => (props.article ? null : "40px")};
  }
`
export const TagsStyle = styled.div`
  a {
    font-weight: 700;
    font-size: 10px;
    color: ${props => props.theme.colors.teal};
    letter-spacing: 0.42px;
    line-height: 100%;
    margin-right: 16px;
    text-decoration: none;
    text-transform: uppercase;

    @media ${device.large} {
      font-size: 12px;
      letter-spacing: 0.5px;
      line-height: 24px;
    }

    :hover {
      opacity: 0.7;
      transition: 70ms;
    }
  }
`
export const Content = styled.div`
  hr {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 1px;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  cite {
    font-size: 14px;
    line-height: 150%;
    font-weight: bold;
    font-style: normal;
    color: black;
    display: inline-block;
    padding-left: 18px;
    position: relative;
    margin-top: 17px;
    @media ${device.large} {
      margin-top: 25px;
    }
    ::before {
      content: "";
      position: absolute;
      left: 0;
      top: 9.5px;
      height: 2px;
      width: 10px;
      background: black;
    }
  }

  blockquote {
    /* font-style: italic; */
    font-weight: 600;
    font-size: 22px;
    color: ${props => props.theme.colors.teal};
    letter-spacing: 0;
    line-height: 32px;
    margin: 25px 0;

    @media ${device.large} {
      font-size: 28px;
      line-height: 42px;
      margin: 40px 0;
    }

    p {
      /* font-style: italic; */
      font-weight: 600;
      font-size: 22px;
      color: ${props => props.theme.colors.teal};
      letter-spacing: 0;
      line-height: 32px;
      margin: 0;

      @media ${device.large} {
        font-size: 28px;
        line-height: 42px;
      }
    }
  }

  p {
    font-size: 16px;
    color: ${props => props.theme.colors.grey[3]};
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 25px;

    @media ${device.large} {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 30px;
      margin-top: 40px;
      margin-bottom: 45px;
    }
  }

  h2 + p,
  h3 + p,
  h4 + p {
    margin-top: 0;
  }

  a {
    display: inline-block;
    white-space: break-spaces;
    color: ${props => props.theme.colors.black};
    font-weight: 700;
    text-decoration-line: none;
    position: relative;

    :hover {
      color: ${props => props.theme.colors.teal};
      transition: 90ms;
    }

    ::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 2px;
      display: inline-block;
      height: 2px;
      width: 100%;
      background: ${props => props.theme.colors.turquoise};
    }
  }

  ${props =>
    props.article &&
    `

	p:last-of-type {
    display:flex;
    align-items:flex-start;
    flex-direction:column;
		margin-bottom: 0;
		gap: 5px;
	
		a {
      display:block;
      margin-bottom:5px;
			// ::after {
			// 	display: none;
			// }
			font-size: 14px;

			@media ${device.large} {
				font-size: 16px;
			}
		}

		@media (min-width: 550px) {
			flex-direction:row;

			a {
				::after {
					display:block;
				}
			}
			a:last-of-type {
				margin-left: 40px;
				position: relative;

				::before {
					content: '';
					position: absolute;
					height: 120%;
					background: #D7E1E2;
					width: 1px;
					left: -22px;
					top: 1px;
				}
			}
		}

    &:empty {
      display:none;
    }
	}



	blockquote {
		// font-style: italic;
		font-size: 22px;
		color: ${props => props.theme.colors.teal};
		letter-spacing: 0;
		line-height: 32px;
		margin: 25px 0;
		font-weight:600;

		@media ${device.large} {
			font-size: 28px;
			line-height: 42px;
			margin: 40px 0;
		}
	}

	`};

  ul,
  ol {
    margin-left: 18px;
    /* margin-top: -10px; */
    margin-bottom: 25px;

    @media ${device.large} {
      margin-bottom: 50px;

      /* margin-top: -20px; */
    }
  }

  li {
    font-size: 16px;
    list-style-type: none;
    position: relative;
    @media ${device.large} {
      font-size: 20px;
    }

    ::before {
      content: "";
      height: 6px;
      width: 6px;
      background: #24a49e;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: -17px;

      @media ${device.large} {
        top: 12px;
        font-size: 20px;
      }
    }
  }
  h2,
  h3,
  h4,
  h5 {
    margin: 15px 0;
  }

  h2 {
    font-size: 30px;
    @media ${device.desktop} {
      font-size: 36px;
    }
  }

  h3 {
    font-size: 26px;

    @media ${device.desktop} {
      font-size: 32px;
    }
  }

  h4 {
    font-size: 22px;

    @media ${device.desktop} {
      font-size: 28px;
    }
  }

  h5 {
    font-size: 22px;

    @media ${device.desktop} {
      font-size: 24px;
    }
  }
`

export const Published = styled.h6`
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.grey[2]};
  letter-spacing: 0;
  line-height: 12px;

  @media ${device.tablet} {
    font-size: 13px;
    line-height: 30px;
    margin-bottom: 0;
    vertical-align: center;
  }
`
export const ArticleTag = styled(Published)`
  @media (min-width: 550px) {
    padding-left: 20px;
    margin-left: 20px;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      height: 29px;
      background: #d7e1e2;
      width: 1px;
      left: 0;
      top: -7px;

      @media ${device.tablet} {
        top: 0px;
      }
    }
  }
`

export const ArticleTagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (min-width: 550px) {
    flex-direction: row;
  }

  @media ${device.tablet} {
    margin-top: 0px;
  }
  @media ${device.desktop} {
    margin-bottom: 40px;
  }
`
